export interface Point {
  x: number | null;
  y: number | null;
}
export interface Measurement {
  index: number;
  name: string;
  unit: string;
  firstPoint: Point;
  secondPoint: Point;
  measurement: number | null;
  isReliable: boolean;
}

export interface Assessment {
  index: number;
  name: string;
  type: string;
  chosenOption: number | null;
}

export interface Section {
  index: number;
  name: string;
  measurements: Array<Measurement>;
  assessments: Array<Assessment>;
}

export interface EvaluationResult {
  startAt: string | null;
  finishAt: string | null;
  status: string;
  sections: Array<Section>;
  comment: string | null;
}
export default class Session {
  id = "";
  plane = "";
  sessionNo = -1;
  resultTemplate: EvaluationResult = {} as EvaluationResult;
  isAvailable = false;
  userId = "";

  constructor(data?: Partial<Session>) {
    if (data) this.setData(data);
  }

  setData(data: Partial<Session>): void {
    this.id = data.id ?? this.id;
    this.plane = data.plane ?? this.plane;
    this.sessionNo = data.sessionNo ?? this.sessionNo;
    this.resultTemplate = data.resultTemplate ?? this.resultTemplate;
    this.isAvailable = data.isAvailable ?? this.isAvailable;
    this.userId = data.userId ?? this.userId;
  }
}
