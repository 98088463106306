import { EvaluationResult } from "./Session";
export default class Data {
  id = "";
  index = -1;
  width = -1;
  height = -1;
  pixelResolution = -1;
  image = "";
  sessionId = "";
  result: EvaluationResult | null = null;

  constructor(data?: Partial<Data>) {
    if (data) this.setData(data);
  }

  setData(data: Partial<Data>): void {
    this.id = data.id ?? this.id;
    this.index = data.index ?? this.index;
    this.width = data.width ?? this.width;
    this.height = data.height ?? this.height;
    this.pixelResolution = data.pixelResolution ?? this.pixelResolution;
    this.image = data.image ?? this.image;
    this.sessionId = data.sessionId ?? this.sessionId;
    this.result = data.result ?? this.result;
  }
}
