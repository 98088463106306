export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss.SSSSSS";

export enum EvaluationResultStatus {
  SUBMITTED = "submitted",
  UNFILLED = "unfilled",
  REJECTED = "rejected",
}

export enum AssessmentType {
  NORMAL = "normal",
  VISUAL = "visual",
  PRESENT = "present",
}
