import { SESSION, DATA, COUNT } from "@/common/constants/api";
import Data from "@/models/Data";
import { EvaluationResult } from "@/models/Session";
import { AxiosResponse } from "axios";
import { BaseService } from "./base.service";
import { HttpRequest } from "./http.init";
import _ from "lodash";

export class DataService {
  /**
   ******************************
   * @API
   ******************************
   */

  static async getDataCountByFilter(
    sessionId: string,
    filter: {
      isFinished?: boolean;
    }
  ): Promise<AxiosResponse> {
    const params = _.isEmpty(filter)
      ? null
      : BaseService._snakeCasingKeys(filter);
    const response = await new HttpRequest({ auth: true }).instance
      .get(`${SESSION}/${sessionId}/${DATA}/${COUNT}`, {
        params: params,
      })
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    return response;
  }

  static async getDataListByFilter(
    sessionId: string,
    filter: {
      page?: number;
      perPage?: number;
      isFinished?: boolean;
    }
  ): Promise<AxiosResponse> {
    const params = _.isEmpty(filter)
      ? null
      : BaseService._snakeCasingKeys(filter);
    const response = await new HttpRequest({ auth: true }).instance
      .get(`${SESSION}/${sessionId}/${DATA}`, {
        params: params,
      })
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    for (const index in response.data.data) {
      response.data.data[index] = new Data(response.data.data[index]);
    }
    return response;
  }

  static async patchResult(
    dataId: string,
    result: EvaluationResult
  ): Promise<AxiosResponse> {
    const body = {
      result: BaseService._snakeCasingKeys(result),
    };

    const response = await new HttpRequest({ auth: true }).instance
      .patch(`${DATA}/${dataId}`, JSON.stringify(body))
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    return response;
  }
}
