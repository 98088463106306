import { SESSION } from "@/common/constants/api";

import moment from "moment";
import { DATETIME_FORMAT } from "@/models/ValueObjects";
import Session from "@/models/Session";
import { AxiosResponse } from "axios";
import { BaseService } from "./base.service";
import { HttpRequest } from "./http.init";

export class SessionService {
  /**
   ******************************
   * @API
   ******************************
   */

  static async getSessionList(): Promise<AxiosResponse> {
    const response = await new HttpRequest({ auth: true }).instance
      .get(`${SESSION}`)
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    for (const index in response.data.data) {
      response.data.data[index] = new Session(response.data.data[index]);
    }
    return response;
  }

  static async getSessionById(sessionId: string): Promise<AxiosResponse> {
    const response = await new HttpRequest({ auth: true }).instance
      .get(`${SESSION}/${sessionId}`)
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    response.data.data = new Session(response.data.data);
    return response;
  }

  static async patchSubmittedAt(
    sessionId: string,
    submittedAt: string = moment().format(DATETIME_FORMAT)
  ): Promise<AxiosResponse> {
    const body = {
      submitted_at: submittedAt,
    };

    const response = await new HttpRequest({ auth: true }).instance
      .patch(`${SESSION}/${sessionId}`, JSON.stringify(body))
      .catch((err) => {
        throw err;
      });
    response.data = BaseService._camelizeKeys(response.data);
    return response;
  }
}
